<template>
  <WaitWrapper :loading="dataLoading || Object.values(loading).some((x) => x)">
    <div class="d-flex justify-space-between mb-8 align-center">
      <h2 class="main-title mb-0">Users</h2>
      <v-btn color="primary" class="px-4 white--text" @click="addUser">
        <v-icon class="mr-2 white--text">mdi-plus</v-icon>New user
      </v-btn>
    </div>
    <UsersTable
      @showUserDetails="goToUser"
      :loading="Object.values(loading).some((x) => x)"
    />
  </WaitWrapper>
</template>

<script>
import { mapActions, mapState } from "vuex";
import WaitWrapper from "@/components/WaitWrapper";
import UsersTable from "@/components/UsersTable";

export default {
  name: "UserList",
  components: {
    WaitWrapper,
    UsersTable,
  },
  created() {
    if (this.userList.length === 0) {
      this.getUserList();
    }
  },
  computed: {
    ...mapState({
      dataLoading: (state) => state.userList.loading.getUserList,
      loading: (state) => state.admin.loading,
      userList: (state) => state.userList.users,
      createUserResponse: (state) => state.admin.createUserResponse,
      editUserResponse: (state) => state.admin.editUserResponse,
    }),
  },
  methods: {
    ...mapActions({
      getUserList: "userList/getUserList",
      getUserDetails: "admin/getUserDetails",
      resetCreateUserResponse: "admin/resetCreateUserResponse",
    }),
    goToUser(id) {
      if (id) {
        this.$router.push({
          path: `/admin/users/${id}`,
        });
      }
    },
    addUser() {
      this.$router.push({
        path: `/admin/users/new`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-title {
  font-size: 1.75rem;
}
</style>
