var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center flex-wrap justify-space-between"},[_c('div',{staticClass:"d-flex mr-3 mb-3 search-filter-wrapper"},[_c('v-text-field',{staticClass:"no-border",attrs:{"solo":"","dense":"","label":"Search...","append-icon":"mdi-magnify","hide-details":"","data-ga":"users_table_search"},on:{"input":_vm.updateSearch}})],1),_c('div',{staticClass:"mb-3 column-filter-wrapper"},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"cog-icon"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog-outline")])]}}])},[_c('span',[_vm._v("Column filters")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.colFilterMenuOpen,"menuActivator":"#cog-icon","menuItems":_vm.columnFilterMenuItems,"showAll":_vm.showAllCols,"offset":"y","filterType":"column","table":"users_table"},on:{"changeFilters":_vm.toggleColSelection,"clearSelectedItems":_vm.clearSelectedColItems,"setSearchText":_vm.setColumnFilterSearchText,"clearFilterSearch":_vm.clearColumnFilterSearch}})],1)]),_c('DataTable',{attrs:{"className":"no-background","itemKey":"id","search":_vm.searchText,"headers":_vm.mutableTableHeaders,"items":_vm.setUsersList,"itemsPerPage":_vm.itemsPerPage,"loading":_vm.loading},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,on:{"mouseover":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.unselectItem(item)}}},[(_vm.checkColumn('full_name'))?_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('NameAvatar',{staticClass:"mr-2",attrs:{"name":_vm.userInitials(item.full_name),"color":_vm.stringToColor(item.full_name)}}),_c('span',{staticClass:"user-link"},[_c('router-link',{staticClass:"user-link",attrs:{"to":{
                    name: 'UserDetail',
                    params: {
                      userId: item.id,
                    },
                  }}},[_vm._v(" "+_vm._s(item.full_name))])],1)],1)]):_vm._e(),(_vm.checkColumn('email'))?_c('td',[_vm._v(" "+_vm._s(item.email)+" ")]):_vm._e(),(_vm.checkColumn('phone'))?_c('td',[_vm._v(" "+_vm._s(item.phone)+" ")]):_vm._e(),(_vm.checkColumn('orgs') && !_vm.showOrgTooltip)?_c('td',{ref:'org-cell_' + item.id,refInFor:true,staticClass:"truncate",on:{"mouseenter":function($event){return _vm.isEllipsisPresent(item.id, 'org')}}},[_vm._v(" "+_vm._s(item.orgs)+" ")]):_c('v-tooltip',{attrs:{"left":"","max-width":"350","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(_vm.checkColumn('orgs') && _vm.showOrgTooltip)?_c('td',_vm._g(_vm._b({ref:'org-cell_' + item.id,refInFor:true,staticClass:"truncate",on:{"mouseenter":function($event){return _vm.isEllipsisPresent(item.id, 'org')}}},'td',attrs,false),on),[_vm._v(" "+_vm._s(item.orgs)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.orgs))])]),(_vm.checkColumn('sites') && !_vm.showSiteTooltip)?_c('td',{ref:'site-cell_' + item.id,refInFor:true,staticClass:"truncate",on:{"mouseenter":function($event){return _vm.isEllipsisPresent(item.id, 'site')}}},[_vm._v(" "+_vm._s(item.sites)+" ")]):_c('v-tooltip',{attrs:{"left":"","max-width":"350","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(_vm.checkColumn('sites') && _vm.showSiteTooltip)?_c('td',_vm._g(_vm._b({ref:'site-cell_' + item.id,refInFor:true,staticClass:"truncate",on:{"mouseenter":function($event){return _vm.isEllipsisPresent(item.id, 'site')}}},'td',attrs,false),on),[_vm._v(" "+_vm._s(item.sites)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.sites))])])],1)}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }