<template>
  <div>
    <div class="d-flex align-center flex-wrap justify-space-between">
      <div class="d-flex mr-3 mb-3 search-filter-wrapper">
        <v-text-field
          solo
          dense
          label="Search..."
          append-icon="mdi-magnify"
          hide-details
          class="no-border"
          data-ga="users_table_search"
          @input="updateSearch"
        ></v-text-field>
      </div>
      <div class="mb-3 column-filter-wrapper">
        <v-tooltip top :open-delay="popupDelay">
          <template v-slot:activator="{ on, attrs }">
            <v-icon id="cog-icon" class="pointer" v-on="on" v-bind="attrs"
              >mdi-cog-outline</v-icon
            >
          </template>
          <span>Column filters</span>
        </v-tooltip>
        <CustomMenuFilter
          :menuOpen="colFilterMenuOpen"
          menuActivator="#cog-icon"
          :menuItems="columnFilterMenuItems"
          :showAll="showAllCols"
          offset="y"
          filterType="column"
          table="users_table"
          @changeFilters="toggleColSelection"
          @clearSelectedItems="clearSelectedColItems"
          @setSearchText="setColumnFilterSearchText"
          @clearFilterSearch="clearColumnFilterSearch"
        />
      </div>
    </div>
    <DataTable
      className="no-background"
      itemKey="id"
      :search="searchText"
      :headers="mutableTableHeaders"
      :items="setUsersList"
      :itemsPerPage="itemsPerPage"
      :loading="loading"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
            @mouseover="selectItem(item)"
            @mouseleave="unselectItem(item)"
          >
            <td v-if="checkColumn('full_name')">
              <div class="d-flex align-center">
                <NameAvatar
                  :name="userInitials(item.full_name)"
                  :color="stringToColor(item.full_name)"
                  class="mr-2"
                />
                <span class="user-link">
                  <router-link
                    class="user-link"
                    :to="{
                      name: 'UserDetail',
                      params: {
                        userId: item.id,
                      },
                    }"
                  >
                    {{ item.full_name }}</router-link
                  >
                </span>
              </div>
            </td>
            <td v-if="checkColumn('email')">
              {{ item.email }}
            </td>
            <td v-if="checkColumn('phone')">
              {{ item.phone }}
            </td>
            <td
              :ref="'org-cell_' + item.id"
              class="truncate"
              @mouseenter="isEllipsisPresent(item.id, 'org')"
              v-if="checkColumn('orgs') && !showOrgTooltip"
            >
              {{ item.orgs }}
            </td>
            <v-tooltip left max-width="350" :open-delay="popupDelay" v-else>
              <template v-slot:activator="{ on, attrs }">
                <td
                  :ref="'org-cell_' + item.id"
                  class="truncate"
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter="isEllipsisPresent(item.id, 'org')"
                  v-if="checkColumn('orgs') && showOrgTooltip"
                >
                  {{ item.orgs }}
                </td>
              </template>
              <span>{{ item.orgs }}</span>
            </v-tooltip>
            <td
              :ref="'site-cell_' + item.id"
              class="truncate"
              @mouseenter="isEllipsisPresent(item.id, 'site')"
              v-if="checkColumn('sites') && !showSiteTooltip"
            >
              {{ item.sites }}
            </td>
            <v-tooltip left max-width="350" :open-delay="popupDelay" v-else>
              <template v-slot:activator="{ on, attrs }">
                <td
                  :ref="'site-cell_' + item.id"
                  class="truncate"
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter="isEllipsisPresent(item.id, 'site')"
                  v-if="checkColumn('sites') && showSiteTooltip"
                >
                  {{ item.sites }}
                </td>
              </template>
              <span>{{ item.sites }}</span>
            </v-tooltip>
          </tr>
        </tbody>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CustomMenuFilter from "./CustomMenuFilter";
import NameAvatar from "@/components/NameAvatar";
import DataTable from "@/components/DataTable";
import {
  userInitials,
  stringToColor,
  removeDuplicates,
} from "@/helpers/functions";
import { popupDelay } from "@/helpers/variables";

export default {
  name: "UsersTable",
  components: {
    NameAvatar,
    DataTable,
    CustomMenuFilter,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      tableData: [],
      issueStatus: [],
      itemsPerPage: 10,
      title: "",
      searchText: "",
      userInitials,
      stringToColor,
      removeDuplicates,
      showOrgTooltip: false,
      showSiteTooltip: false,
      popupDelay,
      colFilterMenuOpen: false,
      selectableTableHeaders: [],
      mutableTableHeaders: [],
      searchColumnMenuText: "",
      showAllCols: false,
    };
  },
  computed: {
    ...mapState({
      usersList: (state) => state.userList.users,
    }),
    tableHeaders() {
      const headers = [
        { id: 1, text: "User", value: "full_name", show: true },
        { id: 2, text: "Email", value: "email", show: true },
        { id: 3, text: "Phone", value: "phone", show: true },
        { id: 4, text: "Org(s)", value: "orgs", show: true },
        { id: 5, text: "Site(s)", value: "sites", show: true },
      ];
      return headers;
    },
    // Results of filter search if search text present
    columnFilterMenuItems() {
      if (this.searchColumnMenuText) {
        return this.selectableTableHeaders?.filter((header) =>
          header.text
            .toLowerCase()
            .includes(this.searchColumnMenuText?.toLowerCase()),
        );
      } else {
        return this.selectableTableHeaders;
      }
    },
    setUsersList() {
      if (this.selected.length > 0) {
        return this.selected;
      } else if (this.usersList?.length > 0) {
        return this.mapUsersList(this.usersList);
      } else {
        return [{ name: "Loading..." }];
      }
    },
  },
  methods: {
    checkColumn(column) {
      return this.selectableTableHeaders?.find(
        (c) => c.value === column && c.show,
      );
    },
    toggleColSelection(item) {
      let isAMatch = false;
      const index = this.selectableTableHeaders.indexOf(item);
      if (item === "selectAll") {
        this.selectAllColumns();
      } else if (item === "reset") {
        this.clearColumnFilterSearch();
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      } else {
        if (index > -1) {
          this.selectableTableHeaders[index].show =
            !this.selectableTableHeaders[index].show;

          if (this.tableHeaders.length > 0) {
            for (const header of this.tableHeaders) {
              if (
                header.id === this.selectableTableHeaders[index].id &&
                !this.selectableTableHeaders[index].show
              ) {
                this.mutableTableHeaders.splice(
                  this.mutableTableHeaders.indexOf(header),
                  1,
                );
                isAMatch = true;
                break;
              }
            }
            // Place or remove the correct column from mutable header array
            if (!isAMatch) {
              let closestIndex = -1; // Initialize index of closest ID
              let minDifference = Infinity; // Initialize minimum difference
              const idOfMissingHeader = this.selectableTableHeaders[index].id;

              if (
                this.mutableTableHeaders.length > 0 &&
                this.mutableTableHeaders[0]?.id < idOfMissingHeader
              ) {
                for (let i = 0; i < this.mutableTableHeaders.length; i++) {
                  const difference =
                    idOfMissingHeader - this.mutableTableHeaders[i].id;
                  if (difference > 0 && difference < minDifference) {
                    closestIndex = i;
                    minDifference = difference;
                  }
                }
                // Insert header just after the header with the closest smaller id
                this.mutableTableHeaders.splice(
                  closestIndex + 1,
                  0,
                  this.selectableTableHeaders[index],
                );
              } else {
                this.mutableTableHeaders.unshift(
                  this.selectableTableHeaders[index],
                );
              }
            }
          }
        }
      }
    },
    selectAllColumns() {
      this.clearColumnFilterSearch();
      this.showAllCols = true;
      for (const header of this.selectableTableHeaders) {
        if (!header.show) {
          this.showAllCols = false;
          break;
        }
      }
      if (this.showAllCols) {
        this.selectableTableHeaders.forEach((header) => (header.show = false));
        this.mutableTableHeaders = [];
      } else {
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      }
    },
    clearSelectedColItems() {
      this.clearColumnFilterSearch();
      this.selectableTableHeaders.forEach((header) => (header.show = false));
      this.mutableTableHeaders = [];
    },
    clearColumnFilterSearch() {
      this.searchColumnMenuText = "";
    },
    setColumnFilterSearchText(searchText) {
      this.searchColumnMenuText = searchText;
    },
    updateSearch(e) {
      this.searchText = e;
    },
    mapUsersList(users) {
      let usersMap = [];

      if (users.length > 0) {
        usersMap = users.map((user) => ({
          id: user.id,
          email: user.email,
          full_name: user.name_first + " " + user.name_last,
          phone: user.phone,
          time_zone: user.time_zone,
          orgs: this.organizeEntitiesForTable(user.orgs),
          sites: this.organizeEntitiesForTable(user.sites),
        }));
      }

      return usersMap;
    },
    organizeEntitiesForTable(entities) {
      if (entities.length > 0) {
        let entArr = [];
        for (const entity of entities) {
          entArr.push(entity.name);
        }
        const entitiesToString = entArr.toString().replaceAll(",", ", ");
        return entitiesToString;
      } else {
        return "N/A";
      }
    },
    goToUser(id) {
      this.$emit("showUserDetails", id);
    },
    selectItem(item) {
      item.selected = true;
    },
    unselectItem(item) {
      item.selected = false;
    },
    setSelected(type, isObjArray, selectArray) {
      let temp = [];

      if (isObjArray) {
        this.selected = this.setUsersList;
      } else if (!isObjArray) {
        for (const selection of selectArray) {
          for (const user of this.setUsersList) {
            if (user[type].includes(selection)) {
              temp.push(user);
            }
          }
        }
        const dedupedUsers = this.removeDuplicates(temp, "full_name");
        this.selected = dedupedUsers;
      } else {
        this.selected = [];
      }
    },
    isEllipsisPresent(id, type) {
      let ref = null;

      if (type === "org") {
        ref = this.$refs[`org-cell_${id}`][0];

        if (ref.scrollWidth > ref.clientWidth) {
          this.showOrgTooltip = true;
        } else {
          this.showOrgTooltip = false;
        }
      } else if (type === "site") {
        ref = this.$refs[`site-cell_${id}`][0];
        if (ref.scrollWidth > ref.clientWidth) {
          this.showSiteTooltip = true;
        } else {
          this.showSiteTooltip = false;
        }
      }
    },
  },
  watch: {
    tableHeaders: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.selectableTableHeaders = [...data];
          this.mutableTableHeaders = [...data];
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.main-title {
  font-size: 1.75rem;
}
.truncate {
  min-width: 1px;
  max-width: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.org-list {
  list-style-type: none;
  display: inline-block;
}

.user-link {
  color: var(--v-black1-base);
}
.user-link:hover {
  text-decoration: underline;
  color: var(--v-primary-base);
}
</style>
